<template>
  <VSelect
    v-model="localStaff"
    :options="options"
    size="sm"
    searchable
  />
</template>

<script>
import VSelect from '@angustrau/vue-bootstrap-select';
import { getStaff } from '@/api';

export default {
  name: 'staff-select',
  props: ['value'],
  data() {
    return {
      options: [this.value],
    };
  },
  computed: {
    localStaff: {
      get() { return this.value; },
      set(localStaff) { this.$emit('input', localStaff); },
    },
  },
  async created() {
    const staff = await getStaff();
    this.options = staff.map(s => `${s.first_name} ${s.last_name}`.trim());
  },
  components: { VSelect },
};
</script>
