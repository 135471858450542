<template>
  <div class="home">
    <h1 class="title">Interdyn's Return</h1>
    <SearchBox class="search" size="lg" />
    <b-button variant="primary" size="lg" @click="handleAddClick">Add New Return</b-button>

    <AddRecordModal ref="add-modal" />
  </div>
</template>

<script>
import moment from 'moment';
import SearchBox from '@/components/SearchBox.vue';
import AddRecordModal from '@/components/modals/AddRecordModal.vue';
import { newRecord } from '@/util';

export default {
  name: 'home',
  methods: {
    handleAddClick() {
      this.$refs['add-modal'].show();
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('new') !== null) {
      const record = newRecord();
      record.customer_name = urlParams.get('customer') || '';
      record.email_address = urlParams.get('email') || '';
      record.phone_number = urlParams.get('phone') || '';
      record.street_name = urlParams.get('street') || '';
      record.suburb_name = urlParams.get('suburb') || '';
      record.city_name = urlParams.get('state') || '';
      record.post_code = urlParams.get('postcode') || '';
      record.invoice_number = urlParams.get('invoice') || '';
      record.product_sku = urlParams.get('product') || '';
      record.serial_number = urlParams.get('serialNumber') || '';
      record.return_issue = urlParams.get('description') || '';
      record.date_added = moment().format('DD/MM/YY');
      this.$refs['add-modal'].show(record);
    }
  },
  components: { SearchBox, AddRecordModal },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -165px;
}

.title {
  margin-bottom: 32px;
  font-size: 6em;
}

.search {
  margin-bottom: 16px;
  width: 750px;
  max-width: 90%;
}
</style>
