<template>
  <b-modal
    ref="modal"
    title="Delete Staff Member"
    @ok="handleOk"
  >
    <b-container fluid>
      Are you sure you want to delete {{ name }}?
    </b-container>

    <template v-slot:modal-footer="{ ok, cancel, hide }">
      <b-button @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { deleteStaff } from '@/api';

export default {
  name: 'delete-staff-modal',
  data() {
    return {
      staff: null,
      name: '',
    };
  },
  methods: {
    show(staff) {
      this.staff = staff;
      this.name = `${staff.first_name} ${staff.last_name}`.trim();
      this.$refs.modal.show();
    },

    async handleOk() {
      await deleteStaff(this.staff.id);
      this.$emit('update');
    },
  },
};
</script>

<style>

</style>
