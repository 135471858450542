<template>
  <div class="home">
    <h1 class="display-1">STOCK RETURN</h1>
    <h2>A LIST OF ITEMS TO BE CREDITED AND / OR REPLACED</h2>
    <RecordList
      ref="record-list"
      :fields="fields"
      :getRecords="getRecords"
      :searchRecords="searchRecords"
      @update="handleUpdateClick"
      @delete="handleDeleteClick"
    />

    <StockReturnUpdateModal ref="update-modal" @update="refreshRecords" />
    <DeleteRecordModal ref="delete-modal" @update="refreshRecords" />
  </div>
</template>

<script>
import RecordList from '@/components/RecordList.vue';
import StockReturnUpdateModal from '@/components/modals/StockReturnUpdateModal.vue';
import DeleteRecordModal from '@/components/modals/DeleteRecordModal.vue';
import { getStockReturnRecords, searchStockReturnRecords } from '@/api';

export default {
  name: 'stock-return',
  data() {
    return {
      fields: [
        'ticket_number',
        'date_added',
        'customer_name',
        'product_sku',
        'serial_number',
        'credit_required',
        'replacement_required',
        'resolved_ticket',
        'update',
        'delete',
      ],
    };
  },
  methods: {
    getRecords: getStockReturnRecords,
    searchRecords: searchStockReturnRecords,

    refreshRecords() {
      this.$refs['record-list'].refresh();
    },
    handleUpdateClick(recordInfo) {
      this.$refs['update-modal'].show(recordInfo.id);
    },
    handleDeleteClick(recordInfo) {
      this.$refs['delete-modal'].show(recordInfo.id);
    },
  },
  components: {
    StockReturnUpdateModal,
    DeleteRecordModal,
    RecordList,
  },
};
</script>

<style scoped>
.search {
  max-width: 20rem;
}
</style>
