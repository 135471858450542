<template>
  <div class="home">
    <h1 class="display-1">SEARCH</h1>
    <h2>SHOWING RESULTS FOR '{{ $route.query.q }}'</h2>
    <RecordList
      ref="record-list"
      :fields="fields"
      :search="$route.query.q"
      :searchRecords="searchRecords"
      @update="handleUpdateClick"
      @delete="handleDeleteClick"
    />

    <HistoryUpdateModal ref="update-modal" @update="refreshRecords" />
    <DeleteRecordModal ref="delete-modal" @update="refreshRecords" />
  </div>
</template>

<script>
import RecordList from '@/components/RecordList.vue';
import HistoryUpdateModal from '@/components/modals/HistoryUpdateModal.vue';
import DeleteRecordModal from '@/components/modals/DeleteRecordModal.vue';
import { searchAllRecords } from '@/api';

export default {
  name: 'search-results',
  data() {
    return {
      fields: [
        'ticket_number',
        'date_added',
        'customer_name',
        'product_sku',
        'serial_number',
        'type_select',
        'view',
        'delete',
      ],
    };
  },
  methods: {
    searchRecords: searchAllRecords,

    async refreshRecords() {
      this.$refs['record-list'].refresh();
    },
    handleUpdateClick(recordInfo) {
      this.$refs['update-modal'].show(recordInfo.id);
    },
    handleDeleteClick(recordInfo) {
      this.$refs['delete-modal'].show(recordInfo.id);
    },
  },
  components: {
    HistoryUpdateModal,
    DeleteRecordModal,
    RecordList,
  },
};
</script>

<style scoped>

</style>
