<template>
  <RecordEditorModal
    ref="modal"
    title="Update Return"
    :fields="fields"
    :buttons="buttons"
    @update="handleUpdate"
  />
</template>

<script>
import RecordEditorModal from '@/components/modals/RecordEditorModal.vue';
import { updateRecord } from '@/api';

export default {
  name: 'history-update-modal',
  data() {
    return {
      fields: [
        'ticket_number',
        'customer_name',
        'email_address',
        'phone_number',
        'street_name',
        'suburb_name',
        'city_name',
        'post_code',
        'date_added',
        'booked_by',
        'assigned_to',
        'invoice_number',
        'product_sku',
        'serial_number',
        'type_select',
        'credit_required',
        'fault_description',
        'notes_details',
        'replacement_required',
        'replacement_invoice',
        'tracking_number',

        'returned_select',
        'credit_raised',
        'resolved_ticket',
        'file_manager',
      ],

      buttons: [
        'cancel',
        'save',
        'copy',
        'create_label',
        'create_invoice',
      ],
    };
  },
  methods: {
    show(record) {
      this.$refs.modal.show(record);
    },

    async handleUpdate(record) {
      await updateRecord(record);
      this.$emit('update');
    },
  },
  components: { RecordEditorModal },
};
</script>

<style>

</style>
