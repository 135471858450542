<template>
  <div class="home">
    <h1 class="display-1">INCOMING</h1>
    <h2>A LIST OF ALL ITEMS NOT YET RECEIVED</h2>
    <RecordList
      ref="record-list"
      :fields="fields"
      :getRecords="getRecords"
      :searchRecords="searchRecords"
      @update="handleUpdateClick"
      @delete="handleDeleteClick"
    >
      <template v-slot:controls>
        <b-button variant="primary" @click="handleAddClick">Add New Return</b-button>
      </template>
    </RecordList>

    <AddRecordModal ref="add-modal" @update="refreshRecords" />
    <UpdateRecordModal ref="update-modal" @update="refreshRecords" />
    <DeleteRecordModal ref="delete-modal" @update="refreshRecords" />
  </div>
</template>

<script>
import RecordList from '@/components/RecordList.vue';
import AddRecordModal from '@/components/modals/AddRecordModal.vue';
import UpdateRecordModal from '@/components/modals/UpdateRecordModal.vue';
import DeleteRecordModal from '@/components/modals/DeleteRecordModal.vue';
import { getUnreturnedRecords, searchUnreturnedRecords } from '@/api';

export default {
  name: 'incoming',
  data() {
    return {
      fields: [
        'ticket_number',
        'date_added',
        'customer_name',
        'product_sku',
        'serial_number',
        'type_select',
        'inbound_tracking_number',
        'update',
        'delete',
      ],
    };
  },
  methods: {
    getRecords: getUnreturnedRecords,
    searchRecords: searchUnreturnedRecords,

    refreshRecords() {
      this.$refs['record-list'].refresh();
    },
    handleAddClick() {
      this.$refs['add-modal'].show();
    },
    handleUpdateClick(recordInfo) {
      this.$refs['update-modal'].show(recordInfo.id);
    },
    handleDeleteClick(recordInfo) {
      this.$refs['delete-modal'].show(recordInfo.id);
    },
  },
  components: {
    AddRecordModal,
    UpdateRecordModal,
    DeleteRecordModal,
    RecordList,
  },
};
</script>

<style scoped>
.search {
  max-width: 20rem;
}
</style>
