<template>
  <input class="plain-input" v-model="localValue" v-bind="$props" />
</template>

<script>
export default {
  name: 'plain-input',
  props: ['value'],
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
};
</script>

<style>
.plain-input {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  overflow: visible;
}

input.plain-input::-webkit-outer-spin-button, input.plain-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.plain-input[type=number] {
  -moz-appearance: textfield;
}
</style>
