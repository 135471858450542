import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Incoming from '@/views/Incoming.vue';
import StockReturn from '@/views/StockReturn.vue';
import Warranty from '@/views/Warranty.vue';
import Charge from '@/views/Charge.vue';
import History from '@/views/History.vue';
import Settings from '@/views/Settings.vue';
import SearchResults from '@/views/SearchResults.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/incoming',
    name: 'incoming',
    component: Incoming,
  },
  {
    path: '/stock-return',
    name: 'stock-return',
    component: StockReturn,
  },
  {
    path: '/warranty',
    name: 'warranty',
    component: Warranty,
  },
  {
    path: '/charge',
    name: 'charge',
    component: Charge,
  },
  {
    path: '/history',
    name: 'history',
    component: History,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/search',
    name: 'search-results',
    component: SearchResults,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
