<template>
  <div>
    <b-button variant="info" class="create-label-btn" v-b-modal.create-label-modal>
      Create Label
    </b-button>

    <b-modal
      id="create-label-modal"
      title="Return Label"
      size="lg"
      @ok="handlePrint"
    >
      <b-container class="label-container" fluid>
        <div id="label-content">
          <b-row>
            <b-col>
              <div class="label-body">
                <div class="label-group">
                  <label class="label-title">Ticket Number:</label>
                  <PlainInput class="label-input ticket-number" :value="record.ticket_number" />
                </div>
                <div class="label-group">
                  <label class="label-title">Date Added:</label>
                  <PlainInput class="label-input" :value="record.date_added" />
                </div>
                <div class="label-group">
                  <label class="label-title">SKU:</label>
                  <PlainInput class="label-input" :value="record.product_sku" />
                </div>
                <div class="label-group">
                  <label class="label-title">RA Type:</label>
                  <PlainInput
                    class="label-input"
                    :value="typeDisplay"
                    :class="{'ticket-number': isWarranty}"
                  />
                </div>
                <div class="label-group">
                  <label class="label-title">Fault Description:</label>
                  <PlainTextArea class="label-input" rows="8" :value="record.return_issue" />
                </div>
                <div class="label-group">
                  <label class="label-title">Customer:</label>
                  <PlainInput class="label-input" :value="record.customer_name" />
                </div>
                  <div class="timestamp">Received on {{ (new Date).toDateString() }}</div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>

      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="ok()">
          Print
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import print from 'print-js';
import PlainInput from '@/components/PlainInput.vue';
import PlainTextArea from '@/components/PlainTextArea.vue';

export default {
  name: 'create-label-button',
  props: ['record'],
  methods: {
    handlePrint() {
      print({
        printable: 'label-content',
        type: 'html',
        targetStyles: ['*'],
        maxWidth: 350,
      });
    },
  },
  computed: {
    isWarranty() {
      return (this.record.type_select.toLowerCase() === 'warranty');
    },
    typeDisplay() {
      if (this.record.type_select.toLowerCase() === 'warranty') {
        return 'Warranty Repair';
      }
      if (this.record.type_select.toLowerCase() === 'charge') {
        return 'Charge Repair';
      }
      if (this.record.type_select.toLowerCase() === 'stock return') {
        return 'Stock Return/Replacement';
      }
      return this.record.type_select;
    },
  },
  components: { PlainInput, PlainTextArea },
};
</script>

<style>
.label-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-number {
  font-size: 22px;
}

#label-content {
  width: 350px;
  margin: 0;
  padding: 0;
  background-color: white;
}

.timestamp {
  text-align: right !important;
  font-size: 18px;
  width: 100%;
}

.label-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
}

.label-group {
  width: 100%;
  margin-bottom: 0.7rem;
}

.label-title {
  font-weight: bold;
  /* width: 100%; */
  width: 120px;
  float: left;
  text-align: left;
}

.label-input {
  width: 220px;
  /* width: 100%; */
  text-align: right;
  float: right;
}

.create-label-btn {
  background-color: #852ee6 !important;
  border-color: #852ee6 !important;
}

.create-label-btn:hover:hover {
  background-color: #9a45f9;
  border-color: #9a45f9;
}

.create-label-btn:hover:visited {
  background-color: #9a45f9;
  border-color: #9a45f9;
}

.create-label-btn:hover:active {
  background-color: #9a45f9;
  border-color: #9a45f9;
}

.create-label-btn:hover:focus {
  background-color: #9a45f9;
  border-color: #9a45f9;
}
</style>
