<template>
  <div class="home">
    <h1 class="display-1">CHARGE</h1>
    <h2>A LIST OF ALL ITEMS TO BE CHARGED</h2>
    <RecordList
      ref="record-list"
      :fields="fields"
      :getRecords="getRecords"
      :searchRecords="searchRecords"
      @update="handleUpdateClick"
      @delete="handleDeleteClick"
    />

    <WarrantyUpdateModal ref="update-modal" @update="refreshRecords" />
    <DeleteRecordModal ref="delete-modal" @update="refreshRecords" />
  </div>
</template>

<script>
import RecordList from '@/components/RecordList.vue';
import WarrantyUpdateModal from '@/components/modals/WarrantyUpdateModal.vue';
import DeleteRecordModal from '@/components/modals/DeleteRecordModal.vue';
import { getChargeRecords, searchChargeRecords } from '@/api';

export default {
  name: 'charge',
  data() {
    return {
      fields: [
        'ticket_number',
        'date_added',
        'customer_name',
        'product_sku',
        'serial_number',
        'update',
        'delete',
      ],
    };
  },
  methods: {
    getRecords: getChargeRecords,
    searchRecords: searchChargeRecords,

    refreshRecords() {
      this.$refs['record-list'].refresh();
    },
    handleUpdateClick(recordInfo) {
      this.$refs['update-modal'].show(recordInfo.id);
    },
    handleDeleteClick(recordInfo) {
      this.$refs['delete-modal'].show(recordInfo.id);
    },
  },
  components: {
    WarrantyUpdateModal,
    DeleteRecordModal,
    RecordList,
  },
};
</script>

<style scoped>
.search {
  max-width: 20rem;
}
</style>
