<template>
  <div>
    <div v-if="loading" class="loading">
      <b-spinner />
    </div>
    <b-container fluid v-if="!loading">
      <h5>File Upload</h5>
      <b-row no-gutters class="mb-2">
        <b-col>
          <div class="d-flex" v-if="!uploading">
            <div class="flex-grow-1 mr-2">
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </div>
            <b-button variant="outline-secondary" @click="handleUpload">Upload</b-button>
          </div>
          <div v-if="uploading">
            <b-progress :value="uploadProgress" :max="uploadMax" show-progress animated />
          </div>
        </b-col>
      </b-row>
      <b-row
        v-for="(file, index) in files"
        :key="index"
        class="mb-2"
      >
        <b-col>
          <span>
            <b-link class="mr-2" :href="file.url">
              <b-button>
                {{ file.file_name }}
              </b-button>
            </b-link>
            <b-button class="mr-2" variant="info" @click="handlePreview(file)">
              Preview
              <Octicon :icon="previewIcon" />
            </b-button>
            <b-button class="mr-2" variant="danger" @click="handleDelete(file)">
              Delete
            </b-button>
          </span>
        </b-col>
      </b-row>

      <b-modal
        id="previewModal"
        title="File Preview"
        size="xl"
        hide-footer
      >
        <iframe class="preview-frame" :src="previewUrl" />
      </b-modal>
      <DeleteFileModal ref="delete-file-modal" @update="refreshFiles" />
    </b-container>
  </div>
</template>

<script>
import S3 from 'aws-sdk/clients/s3';
import { Octicon, eye } from 'octicons-vue';
import DeleteFileModal from '@/components/modals/DeleteFileModal.vue';
import { getFiles, addFile } from '@/api';
import { guid } from '@/util';

const S3_BUCKET = 'interdyn-ra';
const S3_PATH = 'uploads/';
const s3 = new S3();

export default {
  name: 'file-manager',
  props: ['recordID'],
  data() {
    return {
      loading: false,
      files: [],

      file: null,
      uploading: false,
      uploadProgress: 0,
      uploadMax: 100,

      previewIcon: eye,
      previewUrl: null,
    };
  },
  methods: {
    async refreshFiles() {
      this.loading = true;
      this.files = [];
      this.files = await getFiles(this.recordID);
      this.loading = false;
    },
    async handleUpload() {
      if (this.file) {
        const fileExtension = this.file.name.split('.').slice(-1)[0];
        const fileName = `${guid()}.${fileExtension}`;
        const key = S3_PATH + fileName;

        this.uploading = true;
        this.uploadProgress = 0;
        this.uploadMax = 100;

        const upload = s3.makeUnauthenticatedRequest('putObject', {
          Bucket: S3_BUCKET,
          Key: key,
          Body: this.file,
          ContentDisposition: `attachment; filename=${this.file.name}`,
          ACL: 'public-read',
        });
        upload.on('httpUploadProgress', (progress) => {
          this.uploadProgress = progress.loaded;
          this.uploadMax = progress.total;
        });
        await upload.promise();

        const url = `https://${S3_BUCKET}.s3-ap-southeast-2.amazonaws.com/${key}`;
        const result = await addFile({
          record_id: this.recordID,
          file_name: this.file.name,
          url,
        });
        this.uploading = false;
        this.files.push(result);
      }
    },
    handleDelete(file) {
      this.$refs['delete-file-modal'].show(file);
    },
    async handlePreview(file) {
      this.previewUrl = `/ViewerJS/#${file.url}`;
      this.$bvModal.show('previewModal');
    },
  },
  watch: {
    recordID() {
      this.refreshFiles();
    },
  },
  created() {
    this.refreshFiles();
  },
  components: { DeleteFileModal, Octicon },
};
</script>

<style scoped>
.loading {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-frame {
  border: none;
  width: 100%;
  height: 80vh;
}
</style>
