<template>
  <div>
    <b-form @submit.prevent="handleGoToSearch">
      <b-form-input
        v-model="query"
        type="search"
        placeholder="Search..."
        debounce="500"
        id="search-box-input"
        :size="size"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </b-form>
    <b-popover
      ref="popover"
      target="search-box-input"
      triggers="manual"
      placement="bottom"
      :show="showPopover"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(record) in results"
          :key="record.id"
          button
          @click="handleOpen(record)"
        >
          <div class="search-result">
            <span>{{ record.ticket_number }}</span>
            <span class="record-type">{{ record.type_select }}</span>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-popover>

    <HistoryUpdateModal ref="record-modal" />
  </div>
</template>

<script>
import HistoryUpdateModal from '@/components/modals/HistoryUpdateModal.vue';
import { searchAllRecords } from '@/api';

export default {
  name: 'search-box-component',
  props: {
    size: {
      type: String,
      default: 'sm',
    },
  },
  data() {
    return {
      query: '',
      results: [],
      showPopover: false,
    };
  },
  methods: {
    handleOpen(record) {
      this.showPopover = false;
      this.$refs['record-modal'].show(record.id);
    },
    handleGoToSearch() {
      this.$router.push(`/search?q=${encodeURIComponent(this.query)}`);
    },
    handleFocus() {
      if (this.results.length > 0) {
        this.showPopover = true;
      }
    },
    handleBlur() {
      this.showPopover = false;
    },
  },
  watch: {
    async query(newQuery) {
      if (newQuery !== '') {
        const response = await searchAllRecords(newQuery);
        this.results = response.items.slice(0, 5);
        this.showPopover = this.results.length > 0;
      } else {
        this.results = [];
      }
    },
  },
  components: { HistoryUpdateModal },
};
</script>

<style scoped>
.search-result {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.record-type {
  font-weight: bold;
}
</style>
