<template>
  <b-container fluid>
    <b-row class="mb-2">
      <slot name="controls" />
      <div class="right-controls ml-auto">
        <div class="pagination" v-if="pages > 1">
          <b-button :disabled="page === 0" @click="handlePrevPage">Prev</b-button>
          <b-button :disabled="page === pages-1" @click="handleNextPage">Next</b-button>
        </div>
        <b-form-input
          v-if="!search"
          v-model="internalSearch"
          placeholder="Search for a return"
          debounce="1000"
        />
      </div>
    </b-row>
    <b-row>
      <b-table
        class="record-list"
        striped
        show-empty
        small
        hover
        bordered
        :fields="displayedFields"
        :items="displayedRecords"
      >
        <template v-slot:cell(ticket_number)="data">
          <a
            target="_blank"
            :href="`https://interdyn.freshdesk.com/a/tickets/${data.value}`"
          >
            {{ data.value }}
          </a>
        </template>

        <template v-slot:cell(date_added)="data">
          <div :class="isOverdue(data.item) ? 'overdue' : ''">{{ data.value }}</div>
        </template>

        <template v-slot:cell(type_select)="data">
          <div>{{ typeDisplay(data.item) }}</div>
        </template>

        <template v-slot:cell(update)="data">
          <b-button variant="info" class="update-btn" @click="$emit('update', data.item)">
            Update
          </b-button>
        </template>

        <template v-slot:cell(view)="data">
          <b-button variant="info" class="update-btn" @click="$emit('update', data.item)">
            View
          </b-button>
        </template>

        <template v-slot:cell(delete)="data">
          <b-button variant="danger" @click="$emit('delete', data.item)">Delete</b-button>
        </template>

        <template v-slot:cell()="data">
          {{ data.value }}
        </template>

        <template v-slot:empty="scope">
          <div class="empty-message">
            <b-spinner v-if="loading" />
            <span v-if="!loading" >You have sorted all your returns, well done!</span>
          </div>
        </template>

        <template v-slot:emptyfiltered="scope">
          <div class="empty-message">No results.</div>
        </template>
      </b-table>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment';

const lookup = {};
const fields = [
  { tdClass: 'align-middle', key: 'ticket_number', label: 'Ticket #' },
  { tdClass: 'align-middle record-list-date', key: 'date_added', label: 'Date Added' },
  { tdClass: 'align-middle', key: 'customer_name', label: 'Customer Name' },
  { tdClass: 'align-middle', key: 'product_sku', label: 'Product SKU' },
  { tdClass: 'align-middle', key: 'serial_number', label: 'Serial #' },
  { tdClass: 'align-middle', key: 'type_select', label: 'Type' },
  { tdClass: 'align-middle', key: 'returned_select', label: 'Stock Received?' },
  { tdClass: 'align-middle', key: 'credit_required', label: 'Credit Required?' },
  { tdClass: 'align-middle', key: 'replacement_required', label: 'Replacement Required?' },
  { tdClass: 'align-middle', key: 'resolved_ticket', label: 'Resolved?' },
  { tdClass: 'align-middle', key: 'inbound_tracking_number', label: 'Tracking' },
  { tdClass: 'align-middle', key: 'update', label: 'Update' },
  { tdClass: 'align-middle', key: 'view', label: 'View' },
  { tdClass: 'align-middle', key: 'delete', label: 'Delete' },
];
fields.forEach((f) => { lookup[f.key] = f; });

export default {
  name: 'record-list',
  props: ['fields', 'getRecords', 'searchRecords', 'paginated', 'search'],
  data() {
    return {
      loading: true,
      records: [],
      internalSearch: '',
      searchResults: [],
      page: 0,

      totalCount: 0,
      pages: 1,
    };
  },
  computed: {
    displayedFields() {
      return this.fields.map(fieldName => lookup[fieldName]);
    },
    displayedRecords() {
      if (this.internalSearch !== '') {
        return this.searchResults;
      }
      return this.records;
    },
  },
  methods: {
    async refresh() {
      if (this.getRecords) {
        this.records = [];
        this.loading = true;
        const result = await this.getRecords(this.page);
        this.records = result.items;
        this.totalCount = result.total_count;
        this.pages = result.pages;
        this.loading = false;
      }
    },
    async runSearch() {
      this.searchResults = [];
      this.loading = true;
      const result = await this.searchRecords(this.internalSearch);
      this.searchResults = result.items;
      this.totalCount = result.total_count;
      this.pages = result.pages;
      this.loading = false;
    },
    isOverdue(record) {
      const dateAdded = moment(record.date_added, 'DD/MM/YY');
      const now = moment();
      return dateAdded < now.subtract(10, 'days');
    },
    typeDisplay(record) {
      if (record.type_select.toLowerCase() === 'warranty') {
        return 'Warranty Repair';
      }
      if (record.type_select.toLowerCase() === 'charge') {
        return 'Charge Repair';
      }
      if (record.type_select.toLowerCase() === 'stock return') {
        return 'Stock Return/Replacement';
      }
      return record.type_select;
    },
    handleNextPage() {
      this.page = this.page + 1;
      this.refresh();
    },
    handlePrevPage() {
      this.page = this.page - 1;
      this.refresh();
    },
  },
  watch: {
    internalSearch(newSearch) {
      if (newSearch !== '') {
        this.runSearch();
      }
    },
    search(newSearch) {
      if (newSearch) {
        this.internalSearch = newSearch;
      }
    },
  },
  mounted() {
    if (this.search) {
      this.internalSearch = this.search;
    } else {
      this.refresh();
    }
  },
};
</script>

<style scoped>
.empty-message {
  text-align: center;
}

.overdue {
  color: red;
}

.record-list-date {
  width: 110px;
}

.right-controls {
  display: flex;
}

.pagination {
  display: flex;
  margin-right: 8px;
}

.update-btn {
  background-color:#ff9d23;
  border-color: #ff9d23;
}

a, a:hover, a:active, a:visited, a:focus {
  font-weight: 500;
  color: #00b9ff;
}
</style>
