<template>
  <div>
    <b-button variant="info" @click="copy">Copy</b-button>

    <table
      class="copy-content"
      ref="copy-content"
      style="text-align:center;border:1px solid black;width:85%;font-family:Arial;font-size:10pt;"
      cellspacing="0"
    >
      <tbody>
        <tr
          style="background:black;color:white;font-weight:bold;padding-top:5px;padding-bottom:5px;"
        >
          <td style="width:17.5%;">Dealer name</td>
          <td style="width:15%">Product</td>
          <td style="width:10%">Serial Number</td>
          <td style="width:20%">Comments</td>
          <td style="width:7.5%">Allocate to</td>
          <td style="width:10%">Invoice</td>
          <td style="width:10%">Replacement Invoice</td>
          <td style="width:10%">FD Ticket</td>
        </tr>
        <tr>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.customer_name }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.product_sku }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.serial_number }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.return_issue }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            IDA
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.invoice_number }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.replacement_invoice }}
          </td>
          <td
            style="padding:25px;border-right:1px solid rgb(0, 0, 0);"
          >
            {{ record.ticket_number }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'copy-record-button',
  props: ['record'],
  methods: {
    copy() {
      const copyContent = this.$refs['copy-content'];
      copyContent.style.display = 'block';
      const range = document.createRange();
      range.selectNode(copyContent);
      window.getSelection().addRange(range);
      try {
        document.execCommand('copy');
      } catch {
        /* eslint-disable-next-line no-alert */
        alert('Copy failed');
      }
      window.getSelection().removeAllRanges();
      copyContent.style.display = 'none';
    },
  },
};
</script>

<style scoped>
.copy-content {
  display: none;
}
</style>
