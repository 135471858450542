<template>
  <b-modal
    ref="modal"
    title="Delete Return"
    @ok="handleOk"
  >
    <b-container fluid>
      Are you sure you want to delete this return?
    </b-container>

    <template v-slot:modal-footer="{ ok, cancel, hide }">
      <b-button @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { deleteRecord } from '@/api';

export default {
  name: 'delete-record-modal',
  data() {
    return {
      recordId: 0,
    };
  },
  methods: {
    show(recordId) {
      this.recordId = recordId;
      this.$refs.modal.show();
    },

    async handleOk() {
      await deleteRecord(this.recordId);
      this.$emit('update');
    },
  },
};
</script>

<style>

</style>
