<template>
  <textarea class="plain-text-area" v-model="localValue" v-bind="$props" />
</template>

<script>
export default {
  name: 'plain-text-area',
  props: ['value'],
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
};
</script>

<style>
.plain-text-area {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  overflow: visible;

  resize: none;
  word-wrap: break-word;
}
</style>
