<template>
  <div>
    <b-button variant="warning" v-b-modal.create-invoice-modal>Create Invoice</b-button>

    <b-modal
      id="create-invoice-modal"
      title="Service Receipt"
      size="xl"
      @ok="handlePrint"
    >
      <b-container fluid class="loading" v-if="loading">
        <b-spinner />
      </b-container>

      <b-container fluid v-if="!loading">
        <div id="invoice-content">
          <b-row class="invoice-section">
            <b-col cols="4">
              <img class="invoice-logo" src="@/assets/logo-plain.png" />
            </b-col>
            <b-col cols="4" />
            <b-col cols="4">
              <h1 class="invoice-title">Service Receipt</h1>
            </b-col>
          </b-row>
          <b-row class="invoice-section">
            <b-col cols="8">
              <div class="invoice-input-group">
                <div class="invoice-label">Customer:</div>
                <PlainInput class="invoice-input" v-model="localValue.customer_name" />
              </div>

              <div class="invoice-input-group">
                <div class="invoice-label">Address:</div>
                <PlainInput class="invoice-input" v-model="localValue.street_name" />
                <PlainInput class="invoice-input" v-model="localValue.suburb_name" />
                <PlainInput class="invoice-input" v-model="localValue.city_name" />
                <PlainInput class="invoice-input" v-model="localValue.post_code" />
              </div>
            </b-col>
            <b-col cols="4">
              <div class="invoice-input-group">
                <div class="invoice-label">Ticket Number:</div>
                <PlainInput class="invoice-input" v-model="localValue.ticket_number" />
              </div>
              <div class="invoice-input-group">
                <div class="invoice-label">Product:</div>
                <PlainInput class="invoice-input" v-model="localValue.product_sku" />
              </div>
              <div class="invoice-input-group">
                <div class="invoice-label">Serial Number:</div>
                <PlainInput class="invoice-input" v-model="localValue.serial_number" />
              </div>
              <div class="invoice-input-group">
                <div class="invoice-label">RA Type:</div>
                <!-- Don't 2-way bind RA type as we can't save the changed value -->
                <PlainInput class="invoice-input" :value="localValue.type_select" />
              </div>
            </b-col>
          </b-row>
          <b-row class="invoice-section">
            <b-col>
              <div class="invoice-input-group">
                <div class="invoice-label">Fault Description:</div>
                <PlainTextArea class="invoice-input" rows="6" v-model="localValue.return_issue" />
              </div>
              <div class="invoice-input-group">
                <div class="invoice-label">Repair Notes:</div>
                <PlainTextArea class="invoice-input" rows="6" v-model="localValue.notes_details" />
              </div>
            </b-col>
          </b-row>
          <b-row class="invoice-section">
            <b-table-simple bordered>
              <b-thead>
                <b-tr>
                  <b-th>#</b-th>
                  <b-th class="invoice-item-description">Item Description</b-th>
                  <b-th>Qty</b-th>
                  <b-th>Price</b-th>
                  <b-th>Total</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(line, index) in invoiceLines"
                  :key="index"
                  :class="isLineValid(line) ? '' : 'invalid-line'"
                >
                  <b-th>{{ index + 1}}</b-th>
                  <b-td>
                    <PlainInput
                      v-model="line.description"
                      class="invoice-line-input"
                      placeholder="Enter Product Name"
                    />
                  </b-td>
                  <b-td>
                    <PlainInput
                      v-model="line.quantity"
                      class="invoice-line-input number-input"
                      type="number"
                      placeholder="Enter Qty"
                    />
                  </b-td>
                  <b-td>
                    <PlainInput
                      v-model="line.price"
                      class="invoice-line-input number-input"
                      type="number"
                      placeholder="Enter Unit Price"
                    />
                  </b-td>
                  <b-td>{{ getLineTotal(line).toFixed(2) }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div class="ml-auto" ref="add-remove-buttons">
              <b-button variant="light" @click="addLine">+</b-button>
              <b-button variant="light" @click="removeLine">-</b-button>
            </div>
          </b-row>
          <b-row class="invoice-section">
            <div class="invoice-totals">
              <b-table-simple bordered>
                <b-tbody class=" number-input">
                  <b-tr>
                    <b-th>Sub Total</b-th>
                    <b-td>{{ getSubTotal().toFixed(2) }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Tax</b-th>
                    <b-td>
                      <div class="invoice-tax">
                        <PlainInput class="number-input" type="number" v-model="tax" />
                        <span>%</span>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Tax Amount</b-th>
                    <b-td>{{ getTaxAmount().toFixed(2) }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Grand Total</b-th>
                    <b-td>{{ getGrandTotal().toFixed(2) }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-row>
        </div>
      </b-container>

      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="handleSave">
          Save
        </b-button>
        <b-button variant="info" class="create-label-btn" @click="ok()">
          Print
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import print from 'print-js';
import { Decimal } from 'decimal.js';
import PlainInput from '@/components/PlainInput.vue';
import PlainTextArea from '@/components/PlainTextArea.vue';
import { getInvoiceLines, updateInvoiceLines, updateRecord } from '@/api';

const ZERO = new Decimal(0);

export default {
  name: 'create-invoice-button',
  props: ['value'],
  data() {
    return {
      invoiceLines: [this.newInvoiceLine()],
      tax: '10',
      loading: false,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
  methods: {
    newInvoiceLine() {
      return {
        record_id: this.localValue ? this.localValue.id : 0,
        line_order: 0,
        description: 'Labour - per hour',
        quantity: '1.0',
        price: '97',
      };
    },
    isLineValid(line) {
      return line.description && line.quantity && line.price;
    },
    doPrint() {
      return new Promise((resolve) => {
        const modifyButtons = this.$refs['add-remove-buttons'];
        modifyButtons.style.display = 'none';

        print({
          printable: 'invoice-content',
          type: 'html',
          targetStyles: ['*'],
          imageStyle: '',
          maxWidth: 1920,
          onPrintDialogClose: resolve,
        });

        modifyButtons.style.display = 'block';
      });
    },
    async saveInvoice() {
      this.invoiceLines = this.invoiceLines
        .filter(this.isLineValid)
        .map((line, index) => ({
          ...line,
          line_order: index,
        }));
      await Promise.all([
        updateInvoiceLines(this.localValue.id, this.invoiceLines),
        updateRecord(this.localValue),
      ]);
    },
    async handlePrint(event) {
      event.preventDefault();

      if (!this.loading) {
        this.loading = true;
        await Promise.all([this.doPrint(), this.saveInvoice()]);
        this.loading = false;
        this.$bvModal.hide('create-invoice-modal');
      }
    },
    async handleSave(event) {
      event.preventDefault();

      if (!this.loading) {
        this.loading = true;
        await this.saveInvoice();
        this.loading = false;
      }
    },
    addLine() {
      this.invoiceLines.push(this.newInvoiceLine());
    },
    removeLine() {
      this.invoiceLines.pop();
      if (this.invoiceLines.length === 0) {
        this.addLine();
      }
    },
    getLineTotal(line) {
      if (!line.quantity || !line.price) {
        return ZERO;
      }

      const qty = new Decimal(line.quantity);
      const price = new Decimal(line.price);
      return qty.times(price);
    },
    getSubTotal() {
      let total = ZERO;
      this.invoiceLines.forEach((line) => {
        total = total.add(this.getLineTotal(line));
      });
      return total;
    },
    getTaxAmount() {
      const tax = new Decimal(this.tax);
      const subTotal = this.getSubTotal();
      return tax.div(100).times(subTotal);
    },
    getGrandTotal() {
      return this.getSubTotal().plus(this.getTaxAmount());
    },
  },
  watch: {
    async localValue(newRecord, oldRecord) {
      if (newRecord !== oldRecord) {
        this.loading = true;
        this.invoiceLines = await getInvoiceLines(newRecord.id);
        if (this.invoiceLines.length === 0) {
          this.addLine();
        }
        this.loading = false;
      }
    },
  },
  components: { PlainInput, PlainTextArea },
};
</script>

<style scoped>
.loading {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-input {
  text-align: right;
}

#invoice-content {
  padding: 10px 50px;
}

.invoice-section {
  margin-bottom: 3rem;
}

.invoice-section:last-child {
  margin-bottom: 0;
}

.invoice-logo {
  width: 100%;
  padding-top: 17px;
}

.invoice-title {
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.invoice-label {
  margin-right: 5px;
  font-weight: bold;
}

.invoice-input-group {
  margin-bottom: 8px;
}

.invoice-input {
  width: 100%;
  overflow-y: hidden;
}

.invoice-item-description {
  width: 60%;
}

.invoice-line-input {
  width: 100%;
}

.invalid-line input {
  color: red !important;
}

.invoice-totals {
  margin-left: auto;
}

.invoice-tax {
  display: flex;
}
</style>
