<template>
  <RecordEditorModal
    ref="modal"
    title="Update Return"
    :fields="fields"
    :disabledFields="disabledFields"
    :highlightFields="highlightFields"
    :buttons="buttons"
    @update="handleUpdate"
  />
</template>

<script>
import RecordEditorModal from '@/components/modals/RecordEditorModal.vue';
import { updateRecord } from '@/api';

export default {
  name: 'warranty-update-modal',
  data() {
    return {
      fields: [
        'ticket_number',
        'customer_name',
        'phone_number',
        'street_name',
        'suburb_name',
        'city_name',
        'post_code',
        'date_added',
        'email_address_alt',
        'product_sku',
        'serial_number',
        'type_select_alt',
        'replacement_required_alt',
        'fault_description',
        'notes_details',
        'tracking_number',
        'resolved_ticket_alt',

        'file_manager',
      ],

      disabledFields: [
        'ticket_number',
        'customer_name',
        'phone_number',
        'street_name',
        'suburb_name',
        'city_name',
        'post_code',
      ],

      highlightFields: [
        'type_select_alt',
        'replacement_required_alt',
        'fault_description',
        'notes_details',
        'tracking_number',
        'resolved_ticket_alt',
      ],

      buttons: [
        'cancel',
        'save',
        'create_invoice',
      ],
    };
  },
  methods: {
    show(record) {
      this.$refs.modal.show(record);
    },

    async handleUpdate(record) {
      await updateRecord(record);
      this.$emit('update');
    },
  },
  components: { RecordEditorModal },
};
</script>

<style>

</style>
