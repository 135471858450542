<template>
  <div class="home">
    <h1 class="display-1">SETTINGS</h1>

    <h3>Staff Members</h3>
    <b-table-simple
      striped
      hover
    >
      <b-thead>
        <b-tr>
          <b-th>First Name</b-th>
          <b-th>Last Name</b-th>
          <b-th class="staff-delete-header" />
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(s, index) in staff" :key="index">
          <b-td>{{ s.first_name }}</b-td>
          <b-td>{{ s.last_name }}</b-td>
          <b-td>
            <b-button class="ml-auto" variant="danger" @click="deleteStaff(s)">Delete</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-form inline>
      <label class="mr-sm-2">New staff member:</label>
      <b-input class="mr-sm-2" placeholder="First Name" v-model="newStaffFirst" />
      <b-input class="mr-sm-2" placeholder="Last Name" v-model="newStaffLast" />
      <b-button variant="outline-secondary" @click="addStaff">Add</b-button>
    </b-form>

    <DeleteStaffModal ref="delete-staff-modal" @update="refreshStaff" />
    <div style="position:absolute; bottom:20px;">
      <b-button variant="outline-primary" @click="exportAllJobs()">
        <template v-if="exportInProgress">
          Please wait....
        </template>
        <template v-else>
          Export All Jobs
        </template>
      </b-button>
    </div>
  </div>
</template>

<script>
import DeleteStaffModal from '@/components/modals/DeleteStaffModal.vue';
import { getStaff, addStaff, getAllRecords } from '@/api';

function download(data, filename) {
  const blob = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const dummy = window.document.createElement('a');
    dummy.href = window.URL.createObjectURL(blob);
    dummy.download = filename;
    document.body.appendChild(dummy);
    dummy.click();
    document.body.removeChild(dummy);
  }
}

export default {
  name: 'settings',
  data() {
    return {
      staff: [],
      newStaffFirst: '',
      newStaffLast: '',
      exportInProgress: false,
    };
  },
  methods: {
    async refreshStaff() {
      this.staff = await getStaff();
    },
    async addStaff() {
      await addStaff(this.newStaffFirst, this.newStaffLast);
      this.newStaffFirst = '';
      this.newStaffLast = '';
      await this.refreshStaff();
    },
    async deleteStaff(staff) {
      this.$refs['delete-staff-modal'].show(staff);
    },

    async exportAllJobs() {
      function prepareLine(line) {
        const newLine = [];
        line.forEach((item) => {
          const sItem = String(item).replaceAll('\n', '   ||   ').replaceAll('\r', '   ||   ');
          newLine.push(`"${sItem}"`);
        });
        return newLine.join(',');
      }
      if (this.exportInProgress === false) {
        this.exportInProgress = true;
        const { items } = await getAllRecords();
        const outputArray = [];
        const headers = Object.keys(items[0]).sort();
        outputArray.push(prepareLine(headers));
        // block line
        items.forEach((item) => {
          const sortedItem = [];
          headers.forEach((header) => {
            sortedItem.push(item[header]);
          });
          outputArray.push(prepareLine(sortedItem));
        });
        // block line
        download(outputArray.join('\n'), 'returns export.csv');
        this.exportInProgress = false;
      }
    },

  },
  async created() {
    this.staff = await getStaff();
  },
  components: { DeleteStaffModal },
};
</script>

<style scoped>
.staff-delete-header {
  width: 150px;
}
</style>
