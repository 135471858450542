<template>
  <b-modal
    ref="modal"
    title="Delete File"
    @ok="handleOk"
  >
    <b-container fluid>
      Are you sure you want to delete {{ file ? file.file_name : '' }}?
    </b-container>

    <template v-slot:modal-footer="{ ok, cancel, hide }">
      <b-button @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { deleteFile } from '@/api';

export default {
  name: 'delete-file-modal',
  data() {
    return {
      file: null,
    };
  },
  methods: {
    show(file) {
      this.file = file;
      this.$refs.modal.show();
    },
    async handleOk() {
      await deleteFile(this.file);
      this.$emit('update');
    },
  },
};
</script>

<style>

</style>
