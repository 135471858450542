export function newRecord() {
  return {
    id: 0,
    ticket_number: '',
    date_added: '',
    customer_name: '',
    email_address: '',
    street_name: '',
    suburb_name: '',
    city_name: '',
    post_code: '',
    product_sku: '',
    serial_number: '',
    type_select: '',
    notes_details: '',
    returned_select: '',
    tracking_number: '',
    credit_raised: '',
    resolved_ticket: '',
    return_issue: '',
    invoice_number: '',
    replacement_required: '',
    replacement_invoice: '',
    phone_number: '',
    booked_by: '',
    assigned_to: '',
    credit_required: '',
    inbound_tracking_number: '',
  };
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
