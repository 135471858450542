<template>
  <VueBootstrapTypeahead
    ref="box"
    v-model="localProduct"
    @hit="onInput"
    :data="options"
    :serializer="serializer"
    size="sm"
    placeholder="Product"
  />
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead/src/components/VueBootstrapTypeahead.vue';
import { getProducts } from '@/api';

let products = null;

export default {
  name: 'product-select',
  props: ['value'],
  data() {
    return {
      options: [],
    };
  },
  computed: {
    localProduct: {
      get() { return this.value; },
      set(localProduct) { this.$emit('input', localProduct); },
    },
  },
  methods: {
    onInput(value) {
      this.localProduct = value.code;
    },
    serializer(product) {
      return `${product.description} (${product.code})`;
    },
  },
  async created() {
    if (!products) {
      products = await getProducts();
    }
    this.options = products;
  },
  mounted() {
    this.$refs.box.inputValue = this.localProduct;
  },
  components: { VueBootstrapTypeahead },
};
</script>
