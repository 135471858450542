<template>
  <datePicker v-model="localValue" :config="config" class="form-control-sm" v-bind="$props" />
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
  name: 'date-picker',
  props: ['value'],
  data() {
    return {
      config: {
        format: 'DD/MM/YY',
      },
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
  components: { datePicker },
};
</script>

<style>

</style>
