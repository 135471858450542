<template>
  <div id="app">
    <div class="app-body center" v-if="authenticating">
      <b-spinner />
    </div>

    <div class="app-body center" v-if="!authenticating && !authenticated">
      <b-container class="login">
        <b-form-group label="Username">
          <b-form-input v-model="username" />
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input v-model="password" type="password" />
        </b-form-group>
        <b-button @click="handleLogin">Login</b-button>
      </b-container>
    </div>

    <div class="app-body" v-if="!authenticating && authenticated">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item active-class="route-active" to="/" exact>Home</b-nav-item>
            <b-nav-item active-class="route-active" to="/incoming">Incoming</b-nav-item>
            <b-nav-item active-class="route-active" to="/stock-return">Stock Return</b-nav-item>
            <b-nav-item active-class="route-active" to="/warranty">Warranty</b-nav-item>
            <b-nav-item active-class="route-active" to="/charge">Charge</b-nav-item>
            <b-nav-item active-class="route-active" to="/history">Log / History</b-nav-item>
            <b-nav-item active-class="route-active" to="/settings">Settings</b-nav-item>
            <b-nav-form v-if="$route.path !== '/'">
              <SearchBox />
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-container class="h-100">
        <router-view />
      </b-container>
    </div>
  </div>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue';
import { authenticated, login } from '@/api';

export default {
  name: 'app',
  data() {
    return {
      authenticating: true,
      authenticated: false,
      username: '',
      password: '',
    };
  },
  methods: {
    async checkLogin() {
      this.authenticating = true;
      this.authenticated = await authenticated();
      this.authenticating = false;
    },
    async handleLogin() {
      await login(this.username, this.password);
      await this.checkLogin();
    },
  },
  created() {
    this.checkLogin();
  },
  components: { SearchBox },
};
</script>

<style scoped>
#app {
  width: 100%;
  height: 100%;
}

.app-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

.login {
  width: 40%;
}

.route-active {
  font-weight: bold;
}
</style>
