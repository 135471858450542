<template>
  <RecordEditorModal
    ref="modal"
    title="Add New Return"
    :fields="fields"
    :buttons="buttons"
    @update="handleUpdate"
  />
</template>

<script>
import RecordEditorModal from '@/components/modals/RecordEditorModal.vue';
import { addRecord } from '@/api';

export default {
  name: 'add-record-modal',
  data() {
    return {
      fields: [
        'ticket_number',
        'customer_name',
        'email_address',
        'phone_number',
        'street_name',
        'suburb_name',
        'city_name',
        'post_code',
        'date_added',
        'booked_by',
        'assigned_to',
        'invoice_number',
        'product_sku',
        'serial_number',
        'type_select',
        'fault_description',
        'inbound_tracking_number',
        'returned_select',
      ],

      buttons: [
        'cancel',
        'add',
      ],
    };
  },
  methods: {
    show(record) {
      this.$refs.modal.show(record);
    },

    async handleUpdate(record) {
      await addRecord(record);
      this.$emit('update');
    },
  },
  components: { RecordEditorModal },
};
</script>

<style>

</style>
