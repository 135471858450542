<template>
  <VueBootstrapTypeahead
    ref="input"
    v-model="localCustomer"
    :serializer="customerSerializer"
    :data="options"
    @hit="handleHit"
    size="sm"
    placeholder="Customer Name"
  />
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.common';
import 'vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.css';
import { getCustomers } from '@/api';

export default {
  name: 'customer-select',
  props: ['value'],
  data() {
    return {
      options: [],
    };
  },
  computed: {
    localCustomer: {
      get() { return this.value; },
      set(localCustomer) { this.$emit('input', localCustomer); },
    },
  },
  methods: {
    customerSerializer(customer) {
      return customer.name;
    },
    handleHit(customer) {
      this.$emit('autocomplete', customer);
    },
  },
  async created() {
    this.options = await getCustomers();
  },
  mounted() {
    this.$refs.input.inputValue = this.value;
  },
  components: { VueBootstrapTypeahead },
};
</script>
