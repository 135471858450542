const API_ENDPOINT = 'https://siokx9u6c5.execute-api.ap-southeast-2.amazonaws.com/default';

let token = window.localStorage.getItem('ra_token');
export function login(username, password) {
  token = btoa(`${username}:${password}`);
  window.localStorage.setItem('ra_token', token);
}

async function request(endpoint, method, body, cache = false) {
  const res = await fetch(API_ENDPOINT + endpoint, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
    },
    body: body ? JSON.stringify(body) : null,
    cache: cache ? 'default' : 'no-cache',
  });

  if (res.status === 401) {
    throw new Error('Authentication error');
  }

  if (res.status === 422) {
    // eslint-disable-next-line no-alert
    alert('Validation error');
    throw new Error('Validation error');
  }

  if (res.status !== 200) {
    // eslint-disable-next-line no-alert
    alert('Network error');
    throw new Error('Network error');
  }

  return res.json();
}

export async function authenticated() {
  try {
    await request('/authenticated', 'GET', null);
    return true;
  } catch (error) {
    return false;
  }
}

export async function addRecord(record) {
  return request('/record', 'POST', record);
}

export async function getRecord(id) {
  return request(`/record/${id}`, 'GET', null);
}

export async function updateRecord(record) {
  return request(`/record/${record.id}`, 'PUT', record);
}

export async function deleteRecord(id) {
  await request(`/record/${id}`, 'DELETE', null);
}

export async function getInvoiceLines(id) {
  const res = await request(`/record/${id}/invoice/lines`, 'GET', null);
  return res.items;
}

export async function updateInvoiceLines(id, lines) {
  return request(`/record/${id}/invoice/lines`, 'POST', { items: lines });
}

export async function getFiles(id) {
  const res = await request(`/record/${id}/file`, 'GET', null);
  return res.items;
}

export async function addFile(file) {
  return request(`/record/${file.record_id}/file`, 'POST', file);
}

export async function deleteFile(file) {
  await request(`/record/${file.record_id}/file/${file.id}`, 'DELETE', null);
}

export async function searchAllRecords(query) {
  return request(`/records?q=${encodeURIComponent(query)}`, 'GET', null);
}

export async function getUnreturnedRecords() {
  return request('/records/unreturned', 'GET', null);
}

export async function searchUnreturnedRecords(query) {
  return request(`/records/unreturned?q=${encodeURIComponent(query)}`, 'GET', null);
}

export async function getStockReturnRecords() {
  return request('/records/stock', 'GET', null);
}

export async function getAllRecords() {
  return request('/records/export/', 'GET', null);
}

export async function searchStockReturnRecords(query) {
  return request(`/records/stock?q=${encodeURIComponent(query)}`, 'GET', null);
}

export async function getWarrantyRecords() {
  return request('/records/warranty', 'GET', null);
}

export async function searchWarrantyRecords(query) {
  return request(`/records/warranty?q=${encodeURIComponent(query)}`, 'GET', null);
}

export async function getChargeRecords() {
  return request('/records/charge', 'GET', null);
}

export async function searchChargeRecords(query) {
  return request(`/records/charge?q=${encodeURIComponent(query)}`, 'GET', null);
}

export async function getHistoryRecords(page) {
  return request(`/records/history/${page}`, 'GET', null);
}

export async function searchHistoryRecords(query) {
  return request(`/records/history/?q=${encodeURIComponent(query)}`, 'GET', null);
}

let cachedStaff = null;
export async function getStaff(refresh) {
  if (cachedStaff && !refresh) {
    return cachedStaff;
  }
  const results = await request('/staff', 'GET', null);
  cachedStaff = results.items;
  return cachedStaff;
}

export async function addStaff(firstName, lastName) {
  await request('/staff', 'POST', { first_name: firstName, last_name: lastName });
  cachedStaff = await getStaff(true);
}

export async function deleteStaff(id) {
  await request(`/staff/${id}`, 'DELETE', null);
  cachedStaff = await getStaff(true);
}

let cachedProducts = null;
export async function getProducts() {
  if (cachedProducts) {
    return cachedProducts;
  }
  const response = await request('/products', 'GET', null, true);
  cachedProducts = response.items;
  return cachedProducts;
}

let cachedCustomers = null;
export async function getCustomers() {
  if (cachedCustomers) {
    return cachedCustomers;
  }
  const response = await request('/customers', 'GET', null, true);
  cachedCustomers = response.items;
  return cachedCustomers;
}
