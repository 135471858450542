<template>
  <div>
    <b-modal
      ref="modal"
      :title="title"
      size="xl"
    >
      <b-container fluid class="loading" v-if="loading">
        <b-spinner />
      </b-container>

      <b-container fluid v-if="!loading">
        <b-row>
          <b-col cols="4" md="3">
            <b-form-group
              label="Ticket #"
              v-if="fields.includes('ticket_number')"
              :disabled="disabledFields.includes('ticket_number')"
            >
              <b-form-input
                size="sm"
                v-model="record.ticket_number"
                placeholder="Ticket #"
                :state="record.ticket_number === '' ? false : undefined"
              />
            </b-form-group>
            <b-form-group
              label="Customer"
              v-if="fields.includes('customer_name')"
              :disabled="disabledFields.includes('customer_name')"
            >
              <CustomerSelect
                v-model="record.customer_name"
                @autocomplete="handleCustomerAutocomplete"
              />
            </b-form-group>
            <b-form-group
              label="Email"
              v-if="fields.includes('email_address')"
              :disabled="disabledFields.includes('email_address')"
            >
              <b-form-input
                size="sm"
                v-model="record.email_address"
                placeholder="p.sherman@sydneydentists.com"
              />
            </b-form-group>
            <b-form-group
              label="Phone Number"
              v-if="fields.includes('phone_number')"
              :disabled="disabledFields.includes('phone_number')"
            >
              <b-form-input size="sm" v-model="record.phone_number" placeholder="Phone Number" />
            </b-form-group>
            <b-form-group
              label="Street Name"
              v-if="fields.includes('street_name')"
              :disabled="disabledFields.includes('street_name')"
            >
              <b-form-input size="sm" v-model="record.street_name" placeholder="42 Wallaby Way" />
            </b-form-group>
            <b-form-group
              label="Suburb"
              v-if="fields.includes('suburb_name')"
              :disabled="disabledFields.includes('suburb_name')"
            >
              <b-form-input size="sm" v-model="record.suburb_name" placeholder="Sydney" />
            </b-form-group>
            <b-form-group
              label="State"
              v-if="fields.includes('city_name')"
              :disabled="disabledFields.includes('city_name')"
            >
              <b-form-input size="sm" v-model="record.city_name" placeholder="NSW" />
            </b-form-group>
            <b-form-group
              label="Post Code"
              v-if="fields.includes('post_code')"
              :disabled="disabledFields.includes('post_code')"
            >
              <b-form-input size="sm" v-model="record.post_code" placeholder="2000" />
            </b-form-group>
          </b-col>
          <b-col cols="4" md="3">
            <b-form-group
              label="Date Added"
              v-if="fields.includes('date_added')"
              :disabled="disabledFields.includes('date_added')"
            >
              <DatePicker v-model="record.date_added" placeholder="Date Added" />
            </b-form-group>
            <b-form-group
              label="Email"
              v-if="fields.includes('email_address_alt')"
              :disabled="disabledFields.includes('email_address_alt')"
            >
              <b-form-input
                size="sm"
                v-model="record.email_address"
                placeholder="p.sherman@sydneydentists.com"
              />
            </b-form-group>
            <b-form-group
              label="Booked By"
              v-if="fields.includes('booked_by')"
              :disabled="disabledFields.includes('booked_by')"
            >
              <StaffSelect v-model="record.booked_by" />
            </b-form-group>
            <b-form-group
              label="Assigned To"
              v-if="fields.includes('assigned_to')"
              :disabled="disabledFields.includes('assigned_to')"
            >
              <StaffSelect v-model="record.assigned_to" />
            </b-form-group>
            <b-form-group
              label="Invoice Number"
              v-if="fields.includes('invoice_number')"
              :disabled="disabledFields.includes('invoice_number')"
            >
              <b-form-input
                size="sm"
                v-model="record.invoice_number"
                placeholder="AU012345"
              />
            </b-form-group>
            <b-form-group label="Product"
              v-if="fields.includes('product_sku')"
              :disabled="disabledFields.includes('product_sku')"
            >
              <ProductSelect v-model="record.product_sku" />
            </b-form-group>
            <b-form-group
              label="Serial #"
              v-if="fields.includes('serial_number')"
              :disabled="disabledFields.includes('serial_number')"
            >
              <b-form-input size="sm" v-model="record.serial_number" placeholder="Serial #" />
            </b-form-group>
            <b-form-group
              label="RA Type"
              v-if="fields.includes('type_select')"
              :disabled="disabledFields.includes('type_select')"
            >
              <b-form-select size="sm" v-model="record.type_select">
                <option selected value="">Select Type</option>
                <option value="Stock Return">Stock Return/Replacement</option>
                <option value="Warranty">Warranty Repair</option>
                <option value="Charge">Charge Repair</option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Credit Required?"
              v-if="fields.includes('credit_required')"
              :disabled="disabledFields.includes('credit_required')"
            >
              <b-form-select size="sm" v-model="record.credit_required">
                <option selected value="">Select Option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4" md="6">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  label="RA Type"
                  v-if="fields.includes('type_select_alt')"
                  :disabled="disabledFields.includes('type_select_alt')"
                >
                  <b-form-select
                    size="sm"
                    v-model="record.type_select"
                    :class="highlightFields.includes('type_select_alt') ? 'yellow' : ''"
                  >
                    <option selected value="">Select Type</option>
                    <option value="Stock Return">Stock Return/Replacement</option>
                    <option value="Warranty">Warranty Repair</option>
                    <option value="Charge">Charge Repair</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Replacement Required"
                  v-if="fields.includes('replacement_required_alt')"
                  :disabled="disabledFields.includes('replacement_required_alt')"
                >
                  <b-form-select
                    size="sm"
                    v-model="record.replacement_required"
                    :class="highlightFields.includes('replacement_required_alt') ? 'yellow' : ''"
                  >
                    <option selected value="">Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              label="Fault Description"
              v-if="fields.includes('fault_description')"
              :disabled="disabledFields.includes('fault_description')"
            >
              <b-form-textarea
                v-model="record.return_issue"
                rows="5"
                size="sm"
                placeholder="Fault Description"
                :class="highlightFields.includes('fault_description') ? 'yellow' : ''"
              />
            </b-form-group>
            <b-form-group
              label="Repair Notes"
              v-if="fields.includes('notes_details')"
              :disabled="disabledFields.includes('notes_details')"
            >
              <b-form-textarea
                v-model="record.notes_details"
                rows="7"
                size="sm"
                placeholder="Repair Notes"
                :class="highlightFields.includes('notes_details') ? 'yellow' : ''"
              />
            </b-form-group>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Replacement Required"
                  v-if="fields.includes('replacement_required')"
                  :disabled="disabledFields.includes('replacement_required')"
                >
                  <b-form-select
                    size="sm"
                    v-model="record.replacement_required"
                    :class="highlightFields.includes('replacement_required') ? 'yellow' : ''"
                  >
                    <option selected value="">Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Replacement Invoice"
                  v-if="fields.includes('replacement_invoice')"
                  :disabled="disabledFields.includes('replacement_invoice')"
                >
                  <b-form-input
                    size="sm"
                    v-model="record.replacement_invoice"
                    placeholder="Replacement Invoice"
                    :class="highlightFields.includes('replacement_invoice') ? 'yellow' : ''"
                  />
                </b-form-group>
                <b-form-group
                  label="Outbound Tracking Number"
                  v-if="fields.includes('tracking_number')"
                  :disabled="disabledFields.includes('tracking_number')"
                >
                  <b-form-input
                    size="sm"
                    v-model="record.tracking_number"
                    placeholder="ABC1234567"
                    :class="highlightFields.includes('tracking_number') ? 'yellow' : ''"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Resolved?"
                  v-if="fields.includes('resolved_ticket_alt')"
                  :disabled="disabledFields.includes('resolved_ticket_alt')"
                >
                  <b-form-select
                    size="sm"
                    v-model="record.resolved_ticket"
                    :class="highlightFields.includes('resolved_ticket_alt') ? 'blue' : ''"
                  >
                    <option selected value="">Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          v-if="
            fields.includes('returned_select') ||
            fields.includes('credit_raised') ||
            fields.includes('resolved_ticket')
          "
        >
          <b-row>
            <b-col>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3">
              <b-form-group
                label="Inbound Tracking Number"
                v-if="fields.includes('inbound_tracking_number')"
                :disabled="disabledFields.includes('inbound_tracking_number')"
              >
                <b-form-input
                  placeholder="ABC1234567"
                  size="sm"
                  v-model="record.inbound_tracking_number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4" md="3">
              <b-form-group
                label="Stock Received?"
                v-if="fields.includes('returned_select')"
                :disabled="disabledFields.includes('returned_select')"
              >
                <b-form-select size="sm" v-model="record.returned_select">
                  <option selected value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3">
              <b-form-group
                label="Credited Raised?"
                v-if="fields.includes('credit_raised')"
                :disabled="disabledFields.includes('credit_raised')"
              >
                <b-form-select size="sm" v-model="record.credit_raised">
                  <option selected value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" md="3">
              <b-form-group
                label="Resolved?"
                v-if="fields.includes('resolved_ticket')"
                :disabled="disabledFields.includes('resolved_ticket')"
              >
                <b-form-select size="sm" v-model="record.resolved_ticket">
                  <option selected value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="0" md="3" />
          </b-row>
        </div>
        <div v-if="fields.includes('file_manager')">
          <b-row>
            <b-col>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <FileManager :recordID="record.id" />
            </b-col>
          </b-row>
        </div>
      </b-container>

      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel" v-if="buttons.includes('cancel')">
          Cancel
        </b-button>
        <b-button variant="primary" @click="saveRecord" v-if="buttons.includes('add')">
          Add Return
        </b-button>
        <b-button variant="primary" @click="saveRecord" v-if="buttons.includes('save')">
          Save Changes
        </b-button>
        <CopyRecordButton :record="record" v-if="buttons.includes('copy')" />
        <CreateLabelButton :record="record" v-if="buttons.includes('create_label')" />
        <CreateInvoiceButton v-model="record" v-if="buttons.includes('create_invoice')" />
      </template>
    </b-modal>

    <b-modal
      ref="choose-address-modal"
      title="Customer Address"
      @ok="handleChooseAddress"
    >
      <b-form-select
        v-model="chosenAddress"
        :options="customerAddressOptions"
      />
    </b-modal>
  </div>
</template>

<script>
import StaffSelect from '@/components/StaffSelect.vue';
import ProductSelect from '@/components/ProductSelect.vue';
import CustomerSelect from '@/components/CustomerSelect.vue';
import CopyRecordButton from '@/components/CopyRecordButton.vue';
import CreateLabelButton from '@/components/CreateLabelButton.vue';
import CreateInvoiceButton from '@/components/CreateInvoiceButton.vue';
import DatePicker from '@/components/DatePicker.vue';
import FileManager from '@/components/FileManager.vue';
import { getRecord } from '@/api';
import { newRecord } from '@/util';

export default {
  name: 'record-editor-modal',
  props: {
    title: String,
    fields: {
      type: Array,
      default: () => [],
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    highlightFields: {
      type: Array,
      default: () => [],
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      record: newRecord(),
      loading: false,

      customerAddressOptions: [],
      chosenAddress: null,
    };
  },
  methods: {
    async show(record) {
      this.loading = true;
      this.$refs.modal.show();
      if (typeof record === 'number') {
        this.record = await getRecord(record);
      } else if (record) {
        this.record = record;
      } else {
        this.record = newRecord();
      }
      this.loading = false;
    },
    async saveRecord() {
      if (!this.loading) {
        this.$emit('update', this.record);
      }
      this.$refs.modal.hide();
    },
    fillAddress(address) {
      this.record.street_name = address.street_name;
      this.record.suburb_name = address.suburb;
      this.record.city_name = address.state;
      this.record.post_code = address.postcode;
    },
    handleCustomerAutocomplete(customer) {
      if (customer.addresses.length === 1) {
        this.fillAddress(customer.addresses[0]);
      } else if (customer.addresses.length > 1) {
        this.customerAddressOptions = customer.addresses.map(address => ({
          value: address,
          text: address.address_type,
        }));
        // eslint-disable-next-line
        this.chosenAddress = customer.addresses[0];
        this.$refs['choose-address-modal'].show();
      }
    },
    handleChooseAddress() {
      this.fillAddress(this.chosenAddress);
    },
  },
  components: {
    StaffSelect,
    ProductSelect,
    CustomerSelect,
    CopyRecordButton,
    CreateLabelButton,
    CreateInvoiceButton,
    DatePicker,
    FileManager,
  },
};
</script>

<style scoped>
.loading {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellow {
  border: 2px solid #f0ad4e;
}

.blue {
  border: 2px solid #1B77BA;
}
</style>
